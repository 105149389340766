<template>
  <div class="create-case">
    <v-dialog v-model="isDialogVisible" persistent width="640px">
      <v-card>
        <v-card-title class="amber"
          ><span class="headline">Create Case</span></v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="4">
                  <v-select
                    :items="caseTypes"
                    v-model="caseType"
                    :rules="caseTypeRules"
                    required
                    label="Case Type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-autocomplete
                    v-model="coveringRep"
                    :items="items"
                    item-text="mail"
                    item-value="mail"
                    :search-input.sync="search"
                    :rules="coveringRepRules"
                    required
                    clearable
                    @click:clear="items = []"
                    @change="checkEmail(coveringRep)"
                    label="MPS E-mail"
                    return-object
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="`${item.displayName}`"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.mail"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-text="item.officeLocation"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col sm="6">
                  <v-text-field
                    v-model="patientName"
                    :rules="patientNameRules"
                    required
                    label="Patient Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="4">
                  <v-menu
                    ref="shareDateMenu"
                    v-model="shareDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="shareDateFormated"
                        readonly
                        :rules="shareDateRules"
                        required
                        label="Share Date"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="shareDate"
                      :max="nowDate"
                      no-title
                      @input="shareDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="4">
                  <v-menu
                    ref="needDateMenu"
                    v-model="needDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="needDateFormated"
                        readonly
                        :rules="needDateRules"
                        required
                        label="Need by Date"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="needDate"
                      :min="nowDate"
                      no-title
                      @input="needDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="3" offset="1">
                  <v-switch v-model="isUrgent" label="Urgent"></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="4">
                  <v-text-field
                    v-model="primaryExamId"
                    :rules="primaryExamIdRules"
                    required
                    label="Primary Exam Id"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="secondaryExamId"
                    label="Secondary Exam Id"
                  ></v-text-field>
                </v-col>
                <v-col sm="3" offset="1">
                  <v-switch v-model="isTDA" label="TDA"></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="isTDA">
                <v-col sm="4">
                  <v-select
                    :items="segRegions"
                    v-model="segRegion"
                    :rules="segRegionRules"
                    required
                    label="Seg Region"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn
            text
            color="warning"
            :disabled="!valid"
            :loading="isLoading"
            @click="create"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="autoRejectDialog" persistent width="480px">
      <v-card>
        <v-card-title class="amber"
          ><span class="headline">Auto reject</span></v-card-title
        >
        <v-card-text>
          <v-row class="mt-3">
            <v-col sm="12">
              <p>
                This case has been flagged for auto-rejection for being
                <b>same/next day expedite</b>
              </p>
              <p>
                Please review the MPS's office location:
                <b>{{ this.coveringRepRegion }}</b>
              </p>
              <p>If their location is <b>NOT in the US</b>, Select <b>NO</b></p>
              <p>Are you sure you want to auto-reject?</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="noAutoReject"
            :loading="isLoading"
            :disabled="isDisabled"
            >No</v-btn
          >
          <v-btn
            text
            color="warning"
            @click="yesAutoReject"
            :loading="isLoading"
            :disabled="isDisabled"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { callApi } from "../services/apiService";

export default {
  name: "create-case",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      require: false,
    },
    cases: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      overlay: false,
      isLoading: false,
      isDisabled: false,
      segWorkflow: "Full Seg",
      caseType: "",
      caseTypes: ["TKA", "THA", "PKA", "Cadaver"],
      caseTypeRules: [(v) => !!v || "Case Type is required"],
      search: null,
      items: [],
      coveringRep: "",
      coveringRepRules: [(v) => !!v || "E-mail is required"],
      patientName: "",
      patientNameRules: [
        (v) => !!v || "Patient name is required",
        (v) => !this.checkPatientName(v) || "Patient name already exists",
      ],
      // nowDate: new Date().toISOString().slice(0, 10),
      nowDate: this.$moment()
        .subtract(4, "hours")
        .toISOString()
        .slice(0, 10),
      shareDateMenu: false,
      // shareDate: new Date().toISOString().slice(0, 10),
      shareDate: this.$moment()
        .subtract(4, "hours")
        .toISOString()
        .slice(0, 10),
      shareDateRules: [(v) => !!v || "Share date is required"],
      needDateMenu: false,
      needDate: "",
      needDateRules: [(v) => !!v || "Need by date is required"],
      isUrgent: false,
      isExpedite: false,
      isRejectExempt: false,
      isWeekend: false,
      isHoliday: false,
      primaryExamId: "",
      primaryExamIdRules: [
        (v) => !!v || "Exam Id is required",
        (v) => !this.checkPrimaryExamId(v) || "Primary exam Id already exists",
      ],
      secondaryExamId: "",
      isTDA: false,
      isTHA4: false,
      thaVersion: "N/A",
      tkaVersion: "N/A",
      segRegion: "",
      segRegionRules: [(v) => !!v || "Region is required"],
      segRegions: [
        "US - Fort Lauderdale, FL",
        "US - Kalamazoo, MI",
        "APAC",
        "SSP",
      ],
      coveringRepRegion: "",
      autoRejectDialog: false,
    };
  },
  computed: {
    ...mapGetters("holidays", { holidays: "getHolidays" }),
    ...mapGetters("accounts", { account: "getAccount" }),
    isDialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("dialog-state-change", value);
      },
    },
    shareDateFormated: {
      get() {
        return this.shareDate ? this.$moment(this.shareDate).format("L") : "";
      },
      set() {},
    },
    needDateFormated: {
      get() {
        return this.needDate ? this.$moment(this.needDate).format("L") : "";
      },
      set() {},
    },
    pScore() {
      if (this.isUrgent) {
        return `0_${this.$moment(this.needDate).format(
          "YYYYMMDD"
        )}_${this.$moment(this.shareDate).format("YYYYMMDD")}`;
      } else {
        return `1_${this.$moment(this.needDate).format(
          "YYYYMMDD"
        )}_${this.$moment(this.shareDate).format("YYYYMMDD")}`;
      }
    },
    numberOfDays() {
      return true;
    },
  },
  methods: {
    async create() {
      if (
        this.caseType === "TKA" &&
        (this.isExpedite || this.isWeekend || this.isHoliday) &&
        !this.isRejectExempt
      ) {
        this.autoRejectDialog = true;
      } else {
        this.isLoading = true;
        this.isDisabled = true;
        this.case = await callApi(
          "post",
          `${this.$store.state.apiURL}/createCase`,
          {
            caseType: this.caseType,
            coveringRepEmail: this.coveringRep.mail,
            coveringRepGivenName: this.coveringRep.givenName,
            coveringRepSurname: this.coveringRep.surname,
            patientName: this.patientName,
            shareDate: this.shareDate,
            needDate: this.needDate,
            isUrgent: this.isUrgent ? true : false,
            isExpedite: this.isExpedite,
            primaryExamId: this.primaryExamId,
            secondaryExamId: this.secondaryExamId,
            isTDA: this.isTDA ? true : false,
            isTHA4: this.isTHA4 ? true : false,
            thaVersion: this.thaVersion,
            tkaVersion: this.tkaVersion,
            segRegion: this.segRegion,
            caseStatus: "Awaiting Data Review",
            pScore: this.pScore,
            coveringRepRegion: this.coveringRepRegion,
            createdBy: this.$store.state.accounts.account.username,
            createdDate: this.$moment().utc(),
            updatedBy: this.$store.state.accounts.account.username,
            updatedDate: this.$moment().utc(),
            currentlyAssignedRep: "",
            segWorkflow: this.segWorkflow,
          }
        ).then((res) => {
          console.log("Create case response: ", res);
          this.$store.dispatch("cases/getCases");
          this.isLoading = false;
          this.isDisabled = false;
        });
        this.cancel();
      }
      this.$ws.sendToAll({
        message: "Case created!",
        CreatedBy: this.account.username,
        CreatedAt: this.$moment
          .utc()
          .subtract(4, "hours")
          .format("MM-DD-YYYY HH:mm:ss"),
      });
    },
    async noAutoReject() {
      this.isLoading = true;
      this.isDisabled = true;
      this.case = await callApi(
        "post",
        `${this.$store.state.apiURL}/createCase`,
        {
          caseType: this.caseType,
          coveringRepEmail: this.coveringRep.mail,
          coveringRepGivenName: this.coveringRep.givenName,
          coveringRepSurname: this.coveringRep.surname,
          patientName: this.patientName,
          shareDate: this.shareDate,
          needDate: this.needDate,
          isUrgent: this.isUrgent ? true : false,
          isExpedite: this.isExpedite,
          primaryExamId: this.primaryExamId,
          secondaryExamId: this.secondaryExamId,
          isTDA: this.isTDA ? true : false,
          isTHA4: this.isTHA4 ? true : false,
          thaVersion: this.thaVersion,
          tkaVersion: this.tkaVersion,
          segRegion: this.segRegion,
          caseStatus: "Awaiting Data Review",
          pScore: this.pScore,
          coveringRepRegion: this.coveringRepRegion,
          createdBy: this.$store.state.accounts.account.username,
          createdDate: this.$moment().utc(),
          updatedBy: this.$store.state.accounts.account.username,
          updatedDate: this.$moment().utc(),
          currentlyAssignedRep: "",
          segWorkflow: this.segWorkflow,
        }
      ).then((res) => {
        console.log("Create case response: ", res);
        this.$store.dispatch("cases/getCases");
        this.isLoading = false;
        this.isDisabled = false;
      });
      this.cancel();
    },
    async yesAutoReject() {
      this.isLoading = true;
      this.isDisabled = true;
      this.case = await callApi(
        "post",
        `${this.$store.state.apiURL}/createCase`,
        {
          caseType: this.caseType,
          coveringRepEmail: this.coveringRep.mail,
          coveringRepGivenName: this.coveringRep.givenName,
          coveringRepSurname: this.coveringRep.surname,
          patientName: this.patientName,
          shareDate: this.shareDate,
          needDate: this.needDate,
          isUrgent: this.isUrgent ? true : false,
          isExpedite: this.isExpedite,
          primaryExamId: this.primaryExamId,
          secondaryExamId: this.secondaryExamId,
          isTDA: this.isTDA ? true : false,
          isTHA4: this.isTHA4 ? true : false,
          thaVersion: this.thaVersion,
          tkaVersion: this.tkaVersion,
          segRegion: this.segRegion,
          caseStatus: "Rejected - Data Review",
          pScore: this.pScore,
          coveringRepRegion: this.coveringRepRegion,
          createdBy: this.$store.state.accounts.account.username,
          createdDate: this.$moment().utc(),
          updatedBy: this.$store.state.accounts.account.username,
          updatedDate: this.$moment().utc(),
          currentlyAssignedRep: "",
          segWorkflow: this.segWorkflow,
          isRejected: true,
          rejectReason: "Same/next day expedite",
          caseNotes: `Primary exam ID: ${this.primaryExamId}, Reject reason: Same/next day expedite`,
        }
      ).then((res) => {
        console.log("Create case response: ", res);
        this.$http.post(
          "https://prod-29.eastus2.logic.azure.com:443/workflows/d2003fc4cb1f4bf2a0a952ce66dd257e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cyQd6j_GEmILsbbCJCj1mYoyLwf-0IYDpUJFNnIlPEk",
          res
        );
        this.isLoading = false;
        this.isDisabled = false;
      });
      this.cancel();
    },
    async cancel() {
      await this.$refs.form.reset();
      this.isDialogVisible = false;
    },
    checkEmail(value) {
      if (!value) {
        return;
      } else {
        return this.users.find(
          (user) => user.email === value.mail && user.userType === "MPS"
        );
      }
    },
    checkPatientName(patientName) {
      if (!patientName) {
        return;
      } else {
        return this.cases.some(
          (item) => item.patientName.toLowerCase() == patientName.toLowerCase()
        );
      }
    },
    checkPrimaryExamId(value) {
      if (!value) {
        return;
      } else {
        return this.cases.some((item) => item.primaryExamId == value);
      }
    },
    checkExpedite() {
      let totalDays = 0;
      let expediteThreshold = this.$store.state.settings.daysToRejectValue;
      const daysArray = () => {
        const days = [];
        const startDate = this.$moment(this.shareDateFormated);
        const endDate = this.$moment(this.needDateFormated);
        while (endDate.diff(startDate, "days") >= 0) {
          days.push(startDate.format("YYYY-MM-DD"));
          startDate.add(1, "days");
        }
        return days;
      };

      const holidays = this.holidays.filter((holiday) =>
        daysArray().includes(holiday.date)
      );

      const shareDayIsholiday = this.holidays.some(
        (holiday) =>
          this.$moment(this.shareDateFormated).format("YYYY-MM-DD") ===
          holiday.date
      );

      const startDate = this.$moment(this.shareDateFormated).format(
        "YYYY-MM-DD"
      );

      const shareDayIsWeekend = false;

      if (
        this.$moment(this.shareDateFormated).isoWeekday() == 6 ||
        this.$moment(this.shareDateFormated).isoWeekday() == 7
      ) {
        console.log(
          "Start Date: ",
          this.$moment(this.shareDateFormated).isoWeekday()
        );
      } else {
        console.log(
          "Start Date: ",
          this.$moment(this.shareDateFormated).isoWeekday()
        );
      }

      console.log("Start Date: ", startDate);
      console.log("Share day is holiday: ", shareDayIsholiday);
      console.log("Share day is weekend: ", shareDayIsWeekend);

      const weekdays = [];
      const fridays = [];
      const weekends = [];

      daysArray().forEach((date) => {
        if (
          this.$moment(date).isoWeekday() == 6 ||
          this.$moment(date).isoWeekday() == 7
        ) {
          weekends.push(date);
        } else if (this.$moment(date).isoWeekday() == 5) {
          fridays.push(date);
        } else {
          weekdays.push(date);
        }
      });

      totalDays = daysArray().length - (weekends.length + holidays.length);

      //   if (totalDays <= 2) {
      //     this.isExpedite = true;
      //   } else {
      //     this.isExpedite = false;
      //   }

      if (expediteThreshold === 0) {
        this.isExpedite = false;
      } else if (totalDays <= expediteThreshold) {
        this.isExpedite = true;
      } else {
        this.isExpedite = false;
      }

      this.isWeekend = weekends.some((day) => day === this.needDate);
      this.isHoliday = holidays.some((day) => day.date === this.needDate);

      console.log({
        weekdays: weekdays,
        fridays: fridays,
        weekends: weekends,
        holidays: holidays,
        totalDays: totalDays,
        Expedite: this.isExpedite,
        isWeekend: this.isWeekend,
        isHoliday: this.isHoliday,
      });
    },
    checkAutoReject() {},
    async uniqueIdentifier(caseType) {
      let counter = await this.getCounterValue("UniqueIdentifier");
      if (!caseType) return;
      let key = "";
      switch (caseType) {
        case "TKA":
          key = "K";
          break;
        case "THA":
          key = "H";
          break;
        case "PKA":
          key = "P";
          break;
        case "Cadaver":
          key = "C";
          break;
      }
      // return key + Math.floor(Math.random() * 899999 + 100000);
      return key.concat(counter);
    },
    async getCounterValue(counter) {
      this.counter = await this.$http
        .get(`${this.$store.state.apiURL}/caseCounter?id=${counter}`)
        .then((res) => {
          return res.data.value;
        });
      return this.counter;
    },
    async getUsers(newValue) {
      await this.$store.dispatch("accounts/getToken");
      setTimeout(() => {
        this.$http
          .get(
            `https://graph.microsoft.com/v1.0/users?$search="mail:${newValue}"&$orderby=displayName&$count=true`,
            {
              headers: {
                authorization: `bearer ${this.$store.state.accounts.accessToken}`,
                ConsistencyLevel: "eventual",
              },
            }
          )
          .then((res) => {
            this.items = res.data.value;
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },
  },
  created() {
    this.$store.dispatch("holidays/getHolidays");
    this.$store.dispatch("settings/getSettings");
  },
  watch: {
    search: function(newValue) {
      if (!newValue) {
        return;
      }
      if (newValue && newValue !== this.coveringRep && newValue.length >= 3) {
        this.getUsers(newValue);
      }
    },
    coveringRep: function(newValue) {
      if (newValue) {
        let user = this.checkEmail(newValue);
        if (!user) {
          this.isUrgent = false;
          this.coveringRepRegion = "US";
        } else {
          this.isUrgent = user.autoUrgent;
          this.coveringRepRegion = user.region;
          this.isRejectExempt = user.autoRejectExempt;
        }
      }
      console.log(this.coveringRepRegion);
      console.log(this.isRejectExempt);
    },
    isUrgent() {
      console.log(this.pScore);
    },
    primaryExamId() {
      if (this.primaryExamId) {
        if (
          this.primaryExamId.includes("R-") ||
          this.primaryExamId.includes("C-") ||
          this.secondaryExamId.includes("R-") ||
          this.secondaryExamId.includes("C-")
        ) {
          this.isTDA = true;
        } else {
          this.isTDA = false;
        }
      }
    },
    secondaryExamId() {
      if (this.secondaryExamId) {
        if (
          this.primaryExamId.includes("R-") ||
          this.primaryExamId.includes("C-") ||
          this.secondaryExamId.includes("R-") ||
          this.secondaryExamId.includes("C-")
        ) {
          this.isTDA = true;
        } else {
          this.isTDA = false;
        }
      }
    },
    shareDateFormated() {
      this.checkExpedite();
      this.checkAutoReject();
    },
    needDateFormated() {
      this.checkExpedite();
      this.checkAutoReject();
    },
  },
};
</script>

<style></style>
